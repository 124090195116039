<template>
    <div class="memod-legal">
        <side-bar />
        <nav-bar-auth v-if="!isLoggedIn" />
        <div class="legal-container">
            <div class="container">
                <div class="legal-nav">
                    <router-link :to="{ name: 'memod-privacy-policy' }">
                        Privacy Policy
                    </router-link>
                    <router-link :to="{ name: 'memod-tos' }">
                        Terms of Service
                    </router-link>
                    <router-link :to="{ name: 'memod-end-user-license-agreement' }">
                        End-User License Agreement
                    </router-link>
                </div>
            </div>
            <router-view />
        </div>
        <landing-footer />
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "MemodLegal",
    components: {
        SideBar: () => import(/* webpackChunkName: "side-bar" */ "@c/organisms/side-bar"),
        LandingFooter: () => import(/* webpackChunkName: "landing-footer" */ "../landing/footer"),
        NavBarAuth: () => import(/* webpackChunkName: "nav-bar-auth" */ "@c/organisms/nav-bar-auth")
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "User/isLoggedIn"
        })
    },
    mounted() {
        window.scrollTo(0, 0);
    }
}
</script>

<style lang="scss" scoped>
.memod-legal {
    background-color: #181818;

    .legal-nav {
        a {
            display: block;
        }
    }

    .legal-container {
        position: relative;
        z-index: 2;

        &::before {
            content: "";
            height: 100vh;
            z-index: -1;
            position: absolute;
            top: 0;
            bottom: -2px;
            left: 0;
            right: 0;
            opacity: 1;
            background: rgb(24,24,24);
            background: linear-gradient(0deg, rgba(24,24,24,1) 0%, rgba(0,0,0,0.8) 100%);
            animation-name: heroOpacity;
            animation-duration: 1s;
            animation-timing-function: ease-in-out;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: -2px;
            left: 0;
            right: 0;
            height: 100vh;
            z-index: -2;
            background-image: url("~@assets/img/founders-landing/hero/bg.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }

        .legal-nav {
            padding-top: 20px;
            display: flex;

            a {
                padding: 12px 24px;
                border-radius: 30px;
                text-decoration: none;
                color: rgba(235, 235, 245, 0.6);
                font-size: 16px;
                font-weight: 600;
                line-height: 18px;

                &.router-link-active {
                    background: #3A3A3C;
                    color: white;
                }
            }
        }

        /deep/ .legal {
            padding-top: 60px;
        }
    }
}
</style>
